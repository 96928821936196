import React, { Fragment, useContext, useEffect, useState } from "react"
import { graphql } from "gatsby"
import { useStaticQuery } from "gatsby"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import Layout from "layout"
import Section from "elements/Section"
import Message from "elements/Message"
import Container from "layout/Container"
import MedicineBox from "elements/MedicineBox"
import ActionButtons from "elements/ActionButtons"
import AddMedicinesSection from "./components/AddMedicinesSection"
import MedEnsureModal from "../../MedEnsureModal"

import { formSchema } from "../../../utils/formSchema"
import { isBrowser } from "../../../../../services/general"
import { isFutureDate } from "../../../../../services/date"
import { AppContext } from "../../../../../context/AppContext"
import { RefillsContext } from "../../RefillsContext/RefillsContext"
import { parseFormField } from "../../../../../services/airtable"
import { generateFormField } from "../../../../Elements/Form/services/form"
import { generateInitialValues } from "../../../../../services/context"
import { useMedensureProducts } from "../../../MedEnsureContext/hooks/useMedEnsureProducts"
import { reformatAirtableData } from "../../services/reformatAirtableData"
import RefillUpload from "../../../../RefillUpload"
import guidelines from "../../../../RefillUpload/RefillsUploadGuidelines/utils/uploadGuidelines.json"
import { getBackPage } from "../../services/getPreviousPage"

const UpdatedPrescriptionForm = ({ pageContext, location }) => {
  const urlSearchParams = new URLSearchParams(location.search)
  const client = urlSearchParams.get("client") || ""
  const { state, dispatch } = useContext(AppContext)
  const { refillsState, refillsDispatch } = useContext(RefillsContext)
  const [filesUploaded, setFilesUploaded] = useState(refillsState.documents)
  const [showSearch, setShowSearch] = useState(
    refillsState.updatedPrescription.medicines.length === 0
  )

  // redirect to personal details if email is empty
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      refillsState?.updatedPrescription?.email?.length === 0
    ) {
      navigate("/medensure/refills/")
    }
  }, [refillsState?.updatedPrescription?.email])

  const data = useMedensureProducts()
  let medensureFormulary = data?.allAirtableallSitelMeProducts?.nodes.map(
    (node) => node.data
  )
  medensureFormulary = medensureFormulary.map(reformatAirtableData)

  medensureFormulary = medensureFormulary.reduce(
    (medicines, currentProduct) => {
      let currentDrug = medicines.find(
        (medicine) => medicine.drugName === currentProduct.drugName
      )
      if (!currentDrug) medicines = [...medicines, currentProduct]
      return medicines
    },
    []
  )

  let formFields = pageContext.formFields
  let validationSchema = formSchema({ fields: formFields })
  let sectionFormFields = parseFormField(formFields)
  sectionFormFields = sectionFormFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )

  let medensureServices = []
  if (isBrowser())
    medensureServices = JSON.parse(sessionStorage.getItem("medensureServices"))

  const handleSubmit = (values, { setErrors }) => {
    if (
      isFutureDate({
        date: `${values?.prescriptionDate?.month?.value} ${values?.prescriptionDate?.date?.value} ${values?.prescriptionDate?.year}`,
      })
    ) {
      setErrors({
        prescriptionDate: {
          month: "Please input a valid prescription date",
          date: "Please input a valid prescription date",
          year: "Please input a valid prescription date",
        },
      })
      return values
    }
    if (refillsState?.updatedPrescription?.medicines?.length === 0)
      return values
    if (refillsState?.documents?.length === 0) return values
    refillsDispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...refillsState,
        updatedPrescription: {
          ...refillsState.updatedPrescription,
          ...values,
          medicines: [...refillsState.updatedPrescription.medicines],
        },
      },
    })

    switch (true) {
      case medensureServices.includes("Cancel refills"):
        navigate("/medensure/refills/cancel-refills")
        return
      case medensureServices.includes("Book a free teleconsult"):
        navigate("/medensure/refills/health-survey")
        return
      default:
        navigate("/medensure/refills/summary")
        return
    }
  }

  let hasMultipleSegment =
    medensureServices.includes("Cancel refills") ||
    medensureServices.includes("Book a free teleconsult")

  const icon = useStaticQuery(graphql`
    {
      document: file(relativePath: { eq: "icons/upload__document.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      prescription: file(
        relativePath: { eq: "icons/upload__prescription.png" }
      ) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const fileUploadPrescription = icon?.prescription?.childImageSharp?.fixed
  const MAX_FILE_SIZE_IN_BYTES = 3000000
  const MAX_FILE_COUNT = 8

  const handleRemoveMedicine = (index) => {
    const medicineList = [...refillsState.updatedPrescription.medicines]
    medicineList.splice(index, 1)
    refillsDispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...refillsState,
        updatedPrescription: {
          ...refillsState.updatedPrescription,
          medicines: medicineList,
        },
      },
    })
    if (medicineList.length === 0) setShowSearch(true)
  }

  useEffect(() => {
    refillsDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [])

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
      pageContext={pageContext}
      path="/medensure/refills"
      display={{ footer: false, helpCenterBanner: false }}
    >
      <Container isCentered>
        <Fragment>
          <RefillUpload
            pageContext={pageContext}
            icon={fileUploadPrescription}
            maxFileCount={MAX_FILE_COUNT}
            maxFileSize={MAX_FILE_SIZE_IN_BYTES}
            guidelines={guidelines}
            dropzoneLabel="Upload Prescription"
            moduleState={refillsState}
            documents={refillsState.documents}
            moduleDispatch={refillsDispatch}
            module="medensure"
          />

          <Formik
            initialValues={{
              ...generateInitialValues({ fields: formFields }),
              ...refillsState.updatedPrescription,
              uploadPrescription: [...(refillsState?.documents || [])],
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ values, setFieldValue, isValid, submitCount, errors }) => (
              <Form>
                {sectionFormFields.map((section) => (
                  <Section
                    title={section?.section}
                    subtitle={section?.subtitle || ""}
                    id={section?.sectionId || ""}
                    className="mt-1 mb-3"
                  >
                    {section?.message && (
                      <Message color={section?.messageColor || "light"}>
                        <div
                          className="content"
                          dangerouslySetInnerHTML={{
                            __html: section?.message,
                          }}
                        />
                      </Message>
                    )}{" "}
                    {section?.fields.map((field) => {
                      if (!field?.referenceAnswer) {
                        return (
                          <Fragment>
                            {generateFormField({
                              setFilesUploaded,
                              formFields: section?.fields,
                              formField: {
                                ...field,
                                disabled:
                                  field.name === "company" || !!field.disabled,
                              },
                              values,
                              setFieldValue,
                              context: {
                                state: refillsState,
                                dispatch: refillsDispatch,
                              },
                              errors,
                              submitCount,
                            })}
                            {!!field?.addDividerAfterField && (
                              <hr className="has-background-light" />
                            )}
                          </Fragment>
                        )
                      }
                      return null
                    })}
                  </Section>
                ))}
                <Section title={"Prescribed Medicines"} className="mt-1 mb-3">
                  <Message color="warning">
                    To ensure accurate dispensing of your medicines, please
                    input all the medicines indicated in your prescription. We
                    will only refill the medicines that you have inputted and
                    are found in your prescription.
                  </Message>
                  {refillsState?.updatedPrescription?.medicines?.map(
                    (medicine, index) => (
                      <MedicineBox
                        handleDelete={() => handleRemoveMedicine(index)}
                      >
                        <p className="is-size-5 has-text-primary has-text-weight-bold">
                          {medicine.molecule}
                        </p>
                        <div className="is-flex is-justify-content-space-between">
                          <p className="is-size-6">
                            {medicine?.molecule === "Vitamin B complex"
                              ? ""
                              : medicine.strength}{" "}
                            {medicine.form}
                          </p>
                          <p className="is-size-6">
                            Taken {medicine.dailyIntake} per day
                          </p>
                        </div>
                      </MedicineBox>
                    )
                  )}
                  <AddMedicinesSection
                    showSearch={showSearch}
                    setShowSearch={setShowSearch}
                    medensureFormulary={medensureFormulary}
                  />
                  {refillsState?.updatedPrescription?.medicines?.length === 0 &&
                    submitCount > 0 && (
                      <p className="has-text-danger help mt-1">
                        Your medicine list cannot be empty
                      </p>
                    )}
                </Section>
                {!isValid && submitCount > 0 && (
                  <Message color="danger">
                    You may have missed some required fields. Please scan
                    through the form and check if your information is complete.
                  </Message>
                )}

                <ActionButtons
                  back={{
                    label: "Back",
                    link: getBackPage(
                      medensureServices,
                      "Upload updated prescription"
                    ),
                  }}
                  submit={{
                    label: hasMultipleSegment ? "Next" : "Summary",
                    disabled:
                      refillsState?.documents?.filter(
                        (document) => !document?.token
                      )?.length > 0,
                  }}
                />
              </Form>
            )}
          </Formik>
        </Fragment>
      </Container>
    </Layout>
  )
}

export default UpdatedPrescriptionForm
